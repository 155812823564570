export const isValidJsonStr = (value: any) => {
  try {
    JSON.parse(value);

    return true;
  } catch (_err) {
    console.error(_err);
    return false;
  }
};

export const removeDuplicates = (arr: any[]) => {
  return [...new Set(arr)];
};
