import React, { FormEvent, useContext, useEffect, useState } from "react";
import { FaPlus, FaRetweet, FaTrash } from "react-icons/fa";
import { Input, SL } from "../../components";
import {
  IconButton,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import EditableHeader from "../../components/EditableHeader";
import {
  createDataset,
  createDatasetTable,
  deleteDataset,
  getDataset,
  listDatasets,
  updateDataset,
} from "./api";
import { HotkeyContext } from "../../reducers/hotkeys.reducer";
import { AddDataset } from "./components/AddDataset";
import { Upload } from "./core/fields";
import { useSnackbar } from "../../hooks/snackbar";

export type DatasetConfig = {
  id?: string;
  title: string;
  description: string;
  link: string[];
  inserted?: number;
  total?: number;
  sample?: any[];
};

export const Datasets: React.FC = () => {
  const hotkeyContext = useContext(HotkeyContext);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [datasets, setDatasets] = useState<DatasetConfig[]>([]);
  const [selectedDataset, setSelectedDataset] = useState<DatasetConfig | null>(
    null
  );
  const [addingDataset, setAddingDataset] = useState(false);

  const fetchDatasets = async () => {
    setDatasets(await listDatasets());
    setLoading(false);
  };

  useEffect(() => {
    fetchDatasets();
  }, []);

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        N: (e) => !loading && handleAddDatasetForm(e),
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["N"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const searchCallback = (search: string) => {
    setSearch(search);
  };

  const selectDatasetCallback = async (datasetConfig: DatasetConfig) => {
    if (datasetConfig !== undefined && datasetConfig.id) {
      setLoading(true);
      const dataset = await getDataset(datasetConfig.id);
      setSelectedDataset(dataset);
      setAddingDataset(false);
      setLoading(false);
    }
  };

  const handleSetDataset = (key: string, value: any) => {
    if (selectedDataset !== null) {
      setSelectedDataset((prev: any) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const handleAddDatasetForm = (e: FormEvent | undefined) => {
    e?.preventDefault();
    setSelectedDataset(null);
    setAddingDataset(true);
  };

  const handleSaveDataset = async () => {
    if (selectedDataset !== null && selectedDataset.id) {
      setLoading(true);

      const dataset = {
        title: selectedDataset.title,
        description: selectedDataset.description,
        link: selectedDataset.link,
      };

      await updateDataset(selectedDataset.id, dataset);
      await fetchDatasets();
      setLoading(false);
    }
  };

  const handleAddDataset = async (newDataset: DatasetConfig) => {
    setLoading(true);

    const dataset = {
      title: newDataset.title,
      description: newDataset.description,
      link: newDataset.link,
    };

    await createDataset(dataset);
    await fetchDatasets();
    setAddingDataset(false);
    setLoading(false);
  };

  const handleRetry = async () => {
    if (selectedDataset !== null && selectedDataset.id) {
      setLoading(true);

      const result = await createDatasetTable({ id: selectedDataset.id });

      if (result.error) {
        snackbar.error(JSON.stringify(result.error))
      } else {
        snackbar.success("Dataset criado com sucesso")
      }

      setLoading(false);
    }
  };

  const handleRemoveDataset = async () => {
    if (selectedDataset !== null && selectedDataset.id) {
      setLoading(true);

      const response = window.confirm(
        "Tem certeza que deseja remover este dataset?"
      );

      if (!response) {
        setLoading(false);
        return;
      }

      await deleteDataset(selectedDataset.id);
      await fetchDatasets();
      setSelectedDataset(null);
      setLoading(false);
    }
  };

  const headers = Object.keys(selectedDataset?.sample?.[0] ?? {});

  return (
    <div className="flex flex-col space-y-6 mb-24 px-6">
      <h1 className="text-2xl md:text-3xl font-black mb-2 text-center">
        Datasets
      </h1>
      <div className="text-end">
        <button
          className="bg-green-600 hover:bg-green-700 text-white px-4 py-2.5 px-8 text-lg rounded-lg disabled:opacity-80"
          onClick={handleAddDatasetForm}
          disabled={loading}
        >
          <FaPlus className="inline" /> Dataset <SL bg="green.400">N</SL>
        </button>
      </div>
      <div className="flex flex-grow border-2 rounded-md">
        <div className="flex flex-col space-y-6 p-6 w-3/12 border-r-2">
          <div>
            <Input
              type="text"
              placeholder="Nome do do dataset"
              size="lg"
              className="flex-grow"
              value={search}
              onChange={(e) => searchCallback(e.target.value)}
            />
          </div>
          <div className="mx-0 px-0">
            {datasets
              .filter(
                (dataset) =>
                  search.trim().length === 0 || dataset.title.includes(search)
              )
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((dataset) => (
                <div
                  key={"preset-item-" + dataset.id}
                  className="px-4 py-2 hover:bg-gray-200"
                >
                  <div
                    onClick={() => selectDatasetCallback(dataset)}
                    className="flex cursor-pointer justify-between w-full items-center"
                  >
                    {dataset.title}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="flex flex-col space-y-6 p-6 w-9/12">
          {loading && (
            <div className="pt-10 text-center">
              <Spinner size="xl" />
            </div>
          )}
          {selectedDataset === null && addingDataset && (
            <AddDataset onAddDataset={handleAddDataset}></AddDataset>
          )}
          {!loading && selectedDataset !== null && (
            <>
              <div className="flex flex-col md:w-1/2 mx-auto">
                <>
                  <EditableHeader
                    value={selectedDataset.title}
                    onTextChange={(text) => handleSetDataset("title", text)}
                    className="text-xl md:text-3xl font-black mb-2 text-center"
                  />
                  <EditableHeader
                    value={selectedDataset.description}
                    onTextChange={(text) =>
                      handleSetDataset("description", text)
                    }
                    className="mb-2 text-center"
                  />
                </>
              </div>
              <div className="w-2/3 mx-auto space-y-4">
                <Tag size={"lg"}>
                  Inserido {selectedDataset.inserted} de {selectedDataset.total}
                </Tag>
                <TableContainer>
                  <Table>
                    <Thead>
                      <Tr>
                        {headers.map((header) => (
                          <Th key={header}>{header}</Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {selectedDataset?.sample?.map((item, index) => (
                        <Tr key={index}>
                          {headers.map((header) => (
                            <Td key={header}>{item[header]}</Td>
                          ))}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </div>
              <div className="w-2/3 mx-auto">
                <Upload
                  key="dataset-uploader"
                  options={{
                    multiple: false,
                    dir: "datasets",
                    supportedExtensions: ["csv"],
                    maxSize: 2 * 1024 * 1024 * 1024, // 2 GB
                  }}
                  value={selectedDataset.link}
                  onChange={(value) => handleSetDataset("link", value)}
                />
              </div>
              <div className="pt-6 text-center">
                <button
                  type="submit"
                  className="bg-yellow-600 hover:bg-yellow-700 text-white text-lg py-2.5 px-8 rounded-lg disabled:opacity-80"
                  onClick={handleSaveDataset}
                  disabled={loading}
                >
                  Salvar <SL bg="yellow.500">S</SL>
                </button>
              </div>
              <div className="flex flex-col space-y-4 text-end">
                <div>
                  <span className="font-bold">Recriar</span>
                  <IconButton
                    size={"lg"}
                    aria-label="Retry dataset"
                    icon={<FaRetweet />}
                    onClick={handleRetry}
                    className="ml-2"
                    disabled={loading}
                  />
                </div>
                <div>
                  <span className="font-bold text-red-500">Remover</span>
                  <IconButton
                    size={"lg"}
                    aria-label="Remove Dataset"
                    icon={<FaTrash />}
                    onClick={handleRemoveDataset}
                    className="ml-2"
                    disabled={loading}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
