import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { FaCaretRight, FaEllipsisV } from "react-icons/fa";
import { StyleContext } from "../../../reducers";
import { isValidJsonStr } from "../core/utils/is-valid-json-str";
import LayersModal from "./LayersModal";

export type FieldMenuEditorProps = {
  field: Field;
  onChange: (config: any) => void;
  onKeyChange?: (key: string) => void;
  options?: { iconSize: number };
  general: any;
};

const KEY = "Chave";
const ITEMS = "Items";
const LAYERS = "Camadas";

export const FieldMenuEditor: React.FC<FieldMenuEditorProps> = ({
  field,
  onChange,
  onKeyChange,
  options,
  general = {},
}) => {
  const menuController = useDisclosure();
  const submenuController = useDisclosure();
  const styleContext = useContext(StyleContext);
  const [layerModalOpen, setLayerModalOpen] = useState(false);
  const [submenuOptions, setSubmenuOptions] = useState<any[]>([]);

  const onMenuClick = async (option: any) => {
    if (option.title === KEY) {
      const value = await (prompt as any)(option.title, field.key, undefined, {
        validator: (value: any) => /^[a-zA-Z][a-zA-Z0-9_$]*$/.test(value),
        errorMessage:
          "A primeira letra deve ser uma letra, e depois podem ser usados números, letras, underscores ou sinais de dólar.",
      });

      if (value !== null && onKeyChange) {
        onKeyChange(value);
      }
    } else if (option.title === ITEMS) {
      const value = await (prompt as any)(
        option.title,
        (field.options as any)?.[option.key]
          ?.map((item: any) => item.key)
          .join("\n")
      );

      if (value !== null) {
        onChange({
          options: {
            ...field.options,
            [option.key]:
              value?.split("\n").map((item: string) => ({
                key: item.trim(),
                value: item.trim(),
              })) ?? [],
          },
        });
      }
    } else if (option.fields?.length) {
      const defaultValue: any = {};

      option.fields?.forEach((formField: any) => {
        defaultValue[formField.key] =
          (field.options as any)?.[formField.key] ?? formField.default ?? "";
      });

      const value = await (prompt as any)(
        option.title,
        defaultValue,
        option.fields
      );

      if (value !== null) {
        onChange({
          options: {
            ...field.options,
            ...value,
          },
        });
      }
    } else if (option.title === LAYERS) {
      setLayerModalOpen(true);
    } else {
      const optionValue =
        (field as any)[option.namespace ?? "options"]?.[option.key] ?? "";
      const value = await (prompt as any)(
        option.title,
        option.type === "object" ? JSON.stringify(optionValue) : optionValue,
        [],
        {
          tooltip: option.tooltip,
          code: option.code,
          validator: option?.validator,
          errorMessage: option?.errorMessage,
          intellisenseObj: option?.intellisenseObj,
        }
      );

      try {
        if (value !== null) {
          onChange({
            [option.namespace ?? "options"]: {
              ...((field as any)?.[option.namespace ?? "options"] ?? {}),
              [option.key]:
                option.type === "object" ? JSON.parse(value) : value,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleSubmenuOpen = (submenuOptions: any[]) => {
    setSubmenuOptions(submenuOptions);
    submenuController.onOpen();
  };

  return (
    <div>
      <LayersModal
        layers={field.options.layers ?? []}
        isOpen={layerModalOpen}
        onSave={(value) => {
          onChange({
            options: {
              ...field.options,
              layers: value,
            },
          });
          setLayerModalOpen(false);
        }}
        onClose={() => {
          setLayerModalOpen(false);
        }}
      />
      <Menu isOpen={menuController.isOpen} onClose={menuController.onClose}>
        <MenuButton
          as={IconButton}
          aria-label="Field Edit Options"
          icon={
            <>
              <div
                className={`${
                  options?.iconSize
                    ? "cursor-pointer p-4 bg-gray-300 rounded-xl"
                    : ""
                }`}
              >
                <FaEllipsisV size={options?.iconSize} />
              </div>
            </>
          }
          onClick={menuController.onOpen}
        />
        <Portal>
          <MenuList
            zIndex={"overlay"}
            bg={styleContext.state.backgroundColor}
            maxHeight="300px"
            overflowY="auto"
          >
            {fieldEditOptions(general)[field.type]?.map(
              (option: any, index: number) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    if (option.submenu) {
                      handleSubmenuOpen(option.submenu);
                    } else {
                      onMenuClick(option);
                      menuController.onClose();
                    }
                  }}
                  icon={option.submenu && <FaCaretRight />}
                  bg={styleContext.state.backgroundColor}
                >
                  {option.title}
                </MenuItem>
              )
            )}
          </MenuList>
        </Portal>
      </Menu>
      <Menu
        isOpen={submenuController.isOpen}
        onClose={submenuController.onClose}
      >
        <MenuButton></MenuButton>
        <Portal>
          <MenuList
            zIndex={"overlay"}
            bg={styleContext.state.backgroundColor}
            maxHeight="300px"
            overflowY="auto"
          >
            {submenuOptions.map((subOption: any, subIndex: number) => (
              <MenuItem
                key={subIndex}
                onClick={() => {
                  onMenuClick(subOption);
                  submenuController.onClose();
                }}
                bg={styleContext.state.backgroundColor}
              >
                {subOption.title}
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>
    </div>
  );
};

type FieldMenuOptions = {
  title: string;
  submenu?: FieldMenuOptions[];
  code?: boolean;
  fields?: any[];
  key?: string;
  namespace?: string;
  tooltip?: string;
  type?: "object";
  validator?: (value: any) => boolean;
  errorMessage?: string;
};

const fieldEditOptions = (
  general: any = {
    $environment: "null",
    $library: "null",
    $user: "null",
    naoMapeado: "null",
  }
): {
  [key: string]: FieldMenuOptions[];
} => {
  const { $environment, $library, $user, ...restGeneral } = general;

  const intellisenseObj = {
    obj: {
      context: {
        test: 123,
      },
      valid: {
        test: 123,
      },
      general: restGeneral,
      $library,
      $user,
      $environment,
    },
    docs: {
      $user: {
        email: `
          **email** - String
          
          Este é o email do usuário
        `,
        id: `
          **id** - String
          
          Este é o ID do usuário
        `,
        document: `
          **document** - String
          
          Este é o Documento do usuário
        `,
        name: `
          **name** - String
          
          Este é o Nome do usuário
        `,
        custom: `
          **custom** - Object
          
          Neste objeto contem informações customizadas do usuário
        `,
      },
    },
  };

  const keyConfig = {
    key: "key",
    title: KEY,
  };

  // options
  const tooltipOption = {
    key: "tooltip",
    title: "Tooltip",
    tooltip: `As dicas podem tem o formato de HTML utilizando <a href="https://tailwind.build/classes" target="_blank" style="color: blue">classes do Tailwind</a> ou texto simples`,
  };

  const personalInformationOption = {
    title: "Informação Pessoal",
    fields: [
      {
        key: "personalData",
        type: "radio",
        default: "false",
        options: {
          label: "",
          items: [
            { key: "Sim", value: "true" },
            { key: "Não", value: "false" },
          ],
        },
      },
    ],
  };

  const enableApostilleOption = {
    title: "Edição no Apostilamento",
    fields: [
      {
        key: "enableApostille",
        type: "radio",
        default: "false",
        options: {
          label: "",
          items: [
            { key: "Sim", value: "true" },
            { key: "Não", value: "false" },
          ],
        },
      },
    ],
  };

  const placeholderOption = { key: "placeholder", title: "Placeholder" };

  const itemsOption = { key: "items", title: ITEMS };

  const requiredOption = {
    title: "Campo obrigatório",
    fields: [
      {
        key: "required",
        type: "radio",
        default: "false",
        options: {
          label: "",
          items: [
            { key: "Sim", value: "true" },
            { key: "Não", value: "false" },
          ],
        },
      },
    ],
  };

  const readOnlyOption = {
    title: "Apenas leitura",
    fields: [
      {
        key: "readOnly",
        type: "radio",
        default: "false",
        options: {
          label: "",
          items: [
            { key: "Sim", value: "true" },
            { key: "Não", value: "false" },
          ],
        },
      },
    ],
  };

  const toggleOptions = {
    title: "Mostrar toggle",
    fields: [
      {
        key: "toggle",
        type: "radio",
        default: "true",
        options: {
          label: "",
          items: [
            { key: "Mostar", value: "true" },
            { key: "Não mostrar", value: "false" },
          ],
        },
      },
    ],
  };

  const showLogOption = {
    title: "Mostrar registro",
    fields: [
      {
        key: "log",
        type: "radio",
        default: "false",
        options: {
          label: "",
          items: [
            { key: "Sim", value: "true" },
            { key: "Não", value: "false" },
          ],
        },
      },
    ],
  };

  const galleryOption = {
    title: "Mostrar Galeria",
    fields: [
      {
        key: "gallery",
        type: "radio",
        default: "false",
        options: {
          label: "",
          items: [
            { key: "Sim", value: "true" },
            { key: "Não", value: "false" },
          ],
        },
      },
    ],
  };

  const uplodIntegrationOption = {
    title: "SEI",
    fields: [
      {
        key: "integration",
        type: "block",
        block: [
          {
            key: "mirrorToSei",
            type: "radio",
            options: {
              label: "Espelhar documento no SEI?",
              items: [
                { key: "Sim", value: "true" },
                { key: "Não", value: "false" },
              ],
            },
          },
          {
            key: "accessLevel",
            type: "radio",
            options: {
              label: "Nível de acesso no SEI?",
              items: [
                { key: "Público", value: "public" },
                { key: "Restrito", value: "private" },
              ],
            },
          },
          {
            key: "legalHypothesis",
            type: "integration",
            options: {},
            expressions: {
              integration: `{ method: 'GET', url: '${process.env.REACT_APP_BACK_END_API}/protocols/integrations/sei/legal-hypothesis' }`,
            },
          },
          {
            key: "privateHypotesisId",
            type: "select",
            options: {
              label: "Identificador da Hipótese Legal?",
            },
            expressions: {
              visible: "context.accessLevel === 'private'",
              options:
                "{ items: Object.values(context.legalHypothesis).map((h) => ({ key: h.name, value: h.id })) }",
            },
          },
          {
            key: "priority",
            type: "input",
            options: {
              type: "number",
              label: "Nível de prioridade na ordem de envio dos documentos",
            },
          },
        ],
        options: {},
      },
    ],
  };

  // expressions
  const tooltipExpressions = `
  <p style="margin-bottom: 12px;">A expressão deve ser simples</p>
  <code style="border: 1px solid;border-radius: 8px;padding: 8px;margin-left: 16px;">$user.age > 18</code>
  <p style="margin-top: 12px;">ou uma função auto-invocada com retorno</p>
  <pre style="border: 1px solid;border-radius: 8px;padding: 8px;margin-left: 16px;margin-top:8px;margin-bottom:8px;">(() => {
const age = $user.age;
const minAge = 18;

return age > minAge;
})()</pre>

  <p><strong>Atenção</strong> para as variáveis globais do sistema para uso nas expressões:</p>
  <ul style="list-style-type: circle;margin-left: 36px;">
    <li>context</li>
    <li>general</li>
    <li>valid</li>
    <li>$library</li>
    <li>$user</li>
    <li>$environment</li>
  </ul>
`;

  const modelExpression = {
    key: "model",
    title: "Modelo",
    namespace: "expressions",
    code: true,
    tooltip: tooltipExpressions,
    intellisenseObj,
  };

  const modelExpressionDescription = {
    key: "modeExpressionDescription",
    title: "Descrição da modelo",
  };

  const visibleExpression = {
    key: "visible",
    title: "Visibilidade",
    namespace: "expressions",
    code: true,
    tooltip: tooltipExpressions,
    intellisenseObj,
  };

  const validExpression = {
    key: "valid",
    title: "Validação",
    namespace: "expressions",
    code: true,
    tooltip: tooltipExpressions,
    intellisenseObj,
  };

  const optionExpression = {
    key: "options",
    title: "Opções",
    namespace: "expressions",
    code: true,
    tooltip: tooltipExpressions,
    intellisenseObj,
  };

  const logExpression = {
    key: "log",
    title: "Registro",
    namespace: "expressions",
    code: true,
    tooltip: tooltipExpressions,
    intellisenseObj,
  };

  const defaultOptions = [
    keyConfig,
    requiredOption,
    readOnlyOption,
    enableApostilleOption,
    personalInformationOption,
    tooltipOption,
    {
      title: "Expressões",
      submenu: [
        modelExpression,
        modelExpressionDescription,
        visibleExpression,
        validExpression,
        optionExpression,
      ],
    },
  ];

  return {
    title: [tooltipOption, visibleExpression],
    subtitle: [tooltipOption, visibleExpression],
    input: [
      ...defaultOptions,
      {
        title: "Especificos",
        submenu: [
          {
            title: "Tipo do campo",
            fields: [
              {
                key: "type",
                type: "radio",
                default: "text",
                options: {
                  label: "",
                  items: [
                    { key: "Texto", value: "text" },
                    { key: "Área", value: "area" },
                    { key: "Moeda", value: "currency" },
                    { key: "Porcentagem", value: "percentage" },
                  ],
                },
              },
            ],
          },
          { key: "decimalScale", title: "Casas decimais" },
          placeholderOption,
          { key: "mask", title: "Máscara" },
        ],
      },
    ],
    textarea: [...defaultOptions, placeholderOption],
    select: [...defaultOptions, placeholderOption, itemsOption],
    radio: [...defaultOptions, itemsOption],
    checkbox: [...defaultOptions, itemsOption],
    upload: [
      keyConfig,
      requiredOption,
      enableApostilleOption,
      personalInformationOption,
      tooltipOption,
      {
        title: "Expressões",
        submenu: [
          modelExpression,
          modelExpressionDescription,
          visibleExpression,
          validExpression,
        ],
      },
      {
        title: "Específicos",
        submenu: [galleryOption, uplodIntegrationOption],
      },
    ],
    link: [
      keyConfig,
      enableApostilleOption,
      tooltipOption,
      {
        title: "Expressões",
        submenu: [
          {
            key: "model",
            title: "Modelo",
            namespace: "expressions",
            code: true,
            tooltip: `
            Retorno esperado 
              <pre style="border: 1px solid;border-radius: 8px;padding: 8px;margin-left: 16px;">(() => ({ "from": "VINCULADO", "to": "VINCULANTE", "by": "TIPO_DE_VINCULO" }))()</pre>
            <br>${tooltipExpressions}
          `,
            intellisenseObj,
          },
          validExpression,
        ],
      },
    ],
    integration: [
      keyConfig,
      enableApostilleOption,
      tooltipOption,
      {
        title: "Expressões",
        submenu: [
          {
            key: "integration",
            title: "Integração",
            namespace: "expressions",
            code: true,
            tooltip: `
            Retorno esperado 
              <pre style="border: 1px solid;border-radius: 8px;padding: 8px;margin-left: 16px;margin-top:8px;">(() => ({ "method": "GET", "url": "ex.: https://api.com/api/v1/resource", "headers": {} }))()</pre>
              <br>${tooltipExpressions}
          `,
            intellisenseObj,
          },
          validExpression,
          logExpression,
          showLogOption,
        ],
      },
    ],
    map: [...defaultOptions, { key: "layers", title: LAYERS }],
    block: [
      keyConfig,
      enableApostilleOption,
      tooltipOption,
      {
        title: "Expressões",
        submenu: [
          modelExpression,
          modelExpressionDescription,
          visibleExpression,
          validExpression,
          optionExpression,
        ],
      },
      toggleOptions,
    ],
    preset: [
      { key: "key", title: "Chave Personalizada" },
      enableApostilleOption,
      {
        title: "Expressões",
        submenu: [visibleExpression, validExpression],
      },
    ],
    array: [
      keyConfig,
      requiredOption,
      readOnlyOption,
      enableApostilleOption,
      tooltipOption,
      {
        title: "Expressões",
        submenu: [
          modelExpression,
          modelExpressionDescription,
          visibleExpression,
          validExpression,
        ],
      },
      { key: "layout", title: "Layout" },
    ],
    table: [
      keyConfig,
      enableApostilleOption,
      tooltipOption,
      {
        title: "Expressões",
        submenu: [
          modelExpression,
          modelExpressionDescription,
          visibleExpression,
          validExpression,
        ],
      },
      {
        title: "Especificos",
        submenu: [
          {
            key: "table",
            title: "Tabela",
            type: "object",
            validator: (value: any) => {
              if (!value) return true;
              return isValidJsonStr(value);
            },
            errorMessage:
              "O JSON informado é inválido, verifique antes de continuar",
          },
          { key: "columns", title: "Número de Colunas" },
          { key: "width", title: "Largura" },
        ],
      },
    ],
    // used atDocumentEditor & PlateEditor
    documentMap: [
      { key: "layers", title: LAYERS },
      { key: "source", title: "Caminho do GeoJSON" },
    ],
  };
};
