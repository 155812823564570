import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  DefaultRouteContext,
  PrivateWrapper,
  PublicWrapper,
} from "./reducers/auth.reducer";
import {
  ConfirmForgetPassword,
  ConfirmSignUp,
  ContestUser,
  Datasets,
  Document,
  DocumentPrint,
  DocumentValidate,
  Environments,
  ForgetPassword,
  Functions,
  MyProtocols,
  PlatePrint,
  Presets,
  Profile,
  Protocol,
  Secrets,
  SignIn,
  SignUp,
  SignUpEditor,
  SubjectEditor,
  Subjects,
} from "./modules";
import { MyAcceptances } from "./modules/protocol/MyAcceptances";
import MapCallback from "./MapCallback";
import { TaxPrint } from "./modules/form/TaxPrint";
import { Apostille } from "./modules/protocol/Apostille";
import { ApostilleOfficial } from "./modules/protocol/ApostilleOfficial";
import { ProtocolValidate } from "./modules/protocol/ProtocolValidate";
import { ProtocolPrint } from "./modules/form/ProtocolPrint";

const DefaultRoute = () => {
  const defaultRoute = useContext(DefaultRouteContext);
  return <Navigate to={defaultRoute} />;
};

const RouteDefinitions = () => (
  <Routes>
    <Route
      path="/login"
      element={
        <PublicWrapper>
          <SignIn />
        </PublicWrapper>
      }
    />
    <Route
      path="/sign-up"
      element={
        <PublicWrapper>
          <SignUp />
        </PublicWrapper>
      }
    />
    <Route
      path="/confirm-sign-up"
      element={
        <PublicWrapper>
          <ConfirmSignUp />
        </PublicWrapper>
      }
    />
    <Route
      path="/contest-user"
      element={
        <PublicWrapper>
          <ContestUser />
        </PublicWrapper>
      }
    />
    <Route
      path="/forget-password"
      element={
        <PublicWrapper>
          <ForgetPassword />
        </PublicWrapper>
      }
    />
    <Route
      path="/confirm-forget-password"
      element={
        <PublicWrapper>
          <ConfirmForgetPassword />
        </PublicWrapper>
      }
    />
    <Route
      path="/profile"
      element={
        <PrivateWrapper>
          <Profile />
        </PrivateWrapper>
      }
    />
    <Route
      path="/subjects"
      element={
        <PrivateWrapper>
          <Subjects />
        </PrivateWrapper>
      }
    />
    <Route
      path="/map-callback"
      element={
        <PublicWrapper>
          <MapCallback />
        </PublicWrapper>
      }
    />
    <Route
      path="/subjects/:id"
      element={
        <PrivateWrapper>
          <SubjectEditor />
        </PrivateWrapper>
      }
    />
    <Route
      path="/datasets"
      element={
        <PrivateWrapper>
          <Datasets />
        </PrivateWrapper>
      }
    />
    <Route
      path="/presets"
      element={
        <PrivateWrapper>
          <Presets />
        </PrivateWrapper>
      }
    />
    <Route
      path="/secrets"
      element={
        <PrivateWrapper>
          <Secrets />
        </PrivateWrapper>
      }
    />
    <Route
      path="/functions"
      element={
        <PrivateWrapper>
          <Functions />
        </PrivateWrapper>
      }
    />
    <Route
      path="/environments"
      element={
        <PrivateWrapper>
          <Environments />
        </PrivateWrapper>
      }
    />
    <Route
      path="/sign-up-editor"
      element={
        <PrivateWrapper>
          <SignUpEditor />
        </PrivateWrapper>
      }
    />
    <Route
      path="/protocols"
      element={
        <PrivateWrapper>
          <MyProtocols />
        </PrivateWrapper>
      }
    />
    <Route
      path="/acceptances"
      element={
        <PrivateWrapper>
          <MyAcceptances />
        </PrivateWrapper>
      }
    />
    <Route
      path="/protocol/create/:id"
      element={
        <PrivateWrapper>
          <Protocol />
        </PrivateWrapper>
      }
    />
    <Route
      path="/apostille/:id"
      element={
        <PrivateWrapper>
          <Apostille />
        </PrivateWrapper>
      }
    />
    <Route
      path="/apostille-extra/:id"
      element={
        <PrivateWrapper>
          <ApostilleOfficial />
        </PrivateWrapper>
      }
    />
    <Route
      path="/protocol/:id/document"
      element={
        <PrivateWrapper>
          <Document />
        </PrivateWrapper>
      }
    />
    <Route
      path="/protocol-validate/:id"
      element={
        <PublicWrapper>
          <ProtocolValidate></ProtocolValidate>
        </PublicWrapper>
      }
    />
    <Route
      path="/plate-print/:id"
      element={
        <PublicWrapper>
          <PlatePrint />
        </PublicWrapper>
      }
    />
    <Route
      path="/protocol-print/:id"
      element={
        <PublicWrapper>
          <ProtocolPrint />
        </PublicWrapper>
      }
    />
    <Route
      path="/document-print/:id"
      element={
        <PublicWrapper>
          <DocumentPrint />
        </PublicWrapper>
      }
    />
    <Route
      path="/tax-print/:id"
      element={
        <PublicWrapper>
          <TaxPrint />
        </PublicWrapper>
      }
    />
    <Route
      path="/document-validate"
      element={
        <PublicWrapper>
          <DocumentValidate />
        </PublicWrapper>
      }
    />

    <Route path="*" element={<DefaultRoute />} />
  </Routes>
);

export default RouteDefinitions;
