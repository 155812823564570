import { IconButton, Spinner, Tag } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { HelpTooltipClickable } from "../../../components";
import { FieldMenuEditor } from "../components/FieldMenuEditor";
import { FormEditor } from "../components/FormEditor";
import { RenderFieldModelCalculation } from "./components/RenderFieldModelCalculation";
import { RenderValidState } from "./components/RenderValidState";
import { FIELD_COMPONENT_MAP, useFieldDynamic } from "./Field";
import { FieldBlock } from "./FieldBlock";
import { FieldBlockEditable } from "./FieldBlockEditable";
import { Integration, Link } from "./fields";
import { LabelEditable } from "./fields/LabelEditable";
import { ParagraphEditable } from "./fields/ParagraphEditable";
import { TitleEditable } from "./fields/TitleEditable";
import { FieldEditableProps, InputFieldTypes } from "./utils/types";

export const FieldEditable: React.FC<FieldEditableProps> = ({
  parent,
  context,
  validContext,
  general,
  field,
  value,
  valid,
  onChange,
  onValidChange,
  onConfigChange,
  onRemove,
}): JSX.Element => {
  const { loading, visible, options, validState, setOptions } = useFieldDynamic(
    field,
    context,
    validContext,
    general,
    value,
    onChange,
    onValidChange
  );

  const [localValue, setLocalValue] = useState(value);
  const [localValid, setLocalValid] = useState(valid);

  const FieldComponent = FIELD_COMPONENT_MAP[field.type] || (() => <></>);

  return (
    <div className="w-full">
      {(field.type === "title" ||
        field.type === "subtitle" ||
        field.type === "link" ||
        field.type === "integration") && (
        <div className="flex space-x-6 w-full">
          <FieldMenuEditor
            field={field}
            general={general}
            onChange={(config) => {
              onConfigChange({
                ...field,
                options: {
                  ...field.options,
                  ...config.options,
                },
                expressions: {
                  ...field.expressions,
                  ...config.expressions,
                },
              });
              setOptions({ ...options, ...config.options });
            }}
            onKeyChange={(key) => onConfigChange({ ...field, key })}
          />
          <div className="w-full">
            {field.type === "title" && (
              <TitleEditable
                key={field.key}
                onChange={(config) => onConfigChange({ ...field, ...config })}
                props={{ ...field } as any}
              />
            )}
            {field.type === "subtitle" && (
              <ParagraphEditable
                key={field.key}
                onChange={(config) => onConfigChange({ ...field, ...config })}
                props={{ ...field } as any}
              />
            )}
            {field.type === "link" && (
              <>
                <div className="flex items-center space-x-4">
                  <div className="flex items-center space-x-4">
                    <span className="font-bold">Campo de vínculo</span>
                    <Tag size={"lg"}>Chave: {field.key}</Tag>
                  </div>
                  {field.options.tooltip && (
                    <HelpTooltipClickable tooltip={field.options.tooltip} />
                  )}
                </div>
                <Link value={value} />
              </>
            )}
            {field.type === "integration" && (
              <>
                <div className="flex items-center space-x-4">
                  <div className="flex items-center space-x-4">
                    <span className="font-bold">Campo de integração</span>
                    <Tag size={"lg"}>Chave: {field.key}</Tag>
                  </div>
                  {field.options.tooltip && (
                    <HelpTooltipClickable tooltip={field.options.tooltip} />
                  )}
                </div>
                <Integration
                  field={field}
                  options={field.options}
                  value={value}
                />
                {loading && <Spinner />}
              </>
            )}
          </div>
          <IconButton
            aria-label="Remove field"
            icon={<FaTrash />}
            onClick={() => onRemove()}
          />
        </div>
      )}
      {parent && InputFieldTypes.includes(field.type) && (
        <div className="flex space-x-6 w-full">
          <FieldMenuEditor
            general={general}
            field={field}
            onChange={(config) => {
              onConfigChange({
                ...field,
                options: {
                  ...field.options,
                  ...config.options,
                },
                expressions: {
                  ...field.expressions,
                  ...config.expressions,
                },
              });
              setOptions({ ...options, ...config.options });
            }}
            onKeyChange={(key) => {
              onConfigChange({ ...field, key });
            }}
          />
          <div className="w-full">
            <div className="flex items-start space-x-4">
              <LabelEditable
                key={field.key}
                onChange={(config) =>
                  onConfigChange({
                    ...field,
                    options: {
                      ...field.options,
                      ...config.options,
                    },
                  })
                }
                props={{ ...field } as any}
              />
              {field.options.tooltip && (
                <HelpTooltipClickable tooltip={field.options.tooltip} />
              )}
              <RenderFieldModelCalculation
                parent={parent}
                field={field}
                context={context}
              />
            </div>
            <FieldComponent
              field={field}
              key={field.key}
              options={options}
              general={general}
              value={value}
              valid={valid}
              context={context}
              onChange={onChange}
              onValidChange={onValidChange}
            />
          </div>
          <IconButton
            aria-label="Remove field"
            icon={<FaTrash />}
            onClick={() => onRemove()}
          />
        </div>
      )}
      {field.type === "block" && field.block && (
        <div
          className={`${field.options.card ? "p-6 w-full border rounded" : ""}`}
        >
          {field.options.card && (
            <div className="flex items-center space-x-2 text-lg">
              <LabelEditable
                key={field.key}
                onChange={(config) =>
                  onConfigChange({
                    ...field,
                    options: {
                      ...field.options,
                      ...config.options,
                    },
                  })
                }
                props={{ ...field } as any}
              />
              {field.options.tooltip && (
                <div className="mb-2">
                  <HelpTooltipClickable tooltip={field.options.tooltip} />
                </div>
              )}
              <div className="flex-grow"></div>
              {field.options.toggle !== false &&
                field.options.toggle !== "false" && (
                  <div
                    className="cursor-pointer text-blue-500 hover:text-blue-700 pr-10"
                    onClick={() =>
                      setOptions({
                        ...options,
                        open: !options.open,
                      })
                    }
                  >
                    {options.open ? "Ocultar" : "Mostrar"}
                  </div>
                )}
              <IconButton
                aria-label="Remove field"
                icon={<FaTrash />}
                onClick={() => onRemove()}
              />
            </div>
          )}
          <FieldBlockEditable
            parent={{ ...field, options }}
            field={field.block}
            layout={field.options.layout}
            general={general}
            value={localValue}
            valid={localValid}
            onChange={(k, v) => {
              setLocalValue((current: any) => {
                const newLocalValue = { ...current, [k]: v };
                onChange(newLocalValue);
                return newLocalValue;
              });
            }}
            onValidChange={(k, v) => {
              setLocalValid((current: any) => {
                const newLocalValid = { ...current, [k]: v };
                onValidChange(newLocalValid);
                return newLocalValid;
              });
            }}
            onConfigChange={(f: Field[]) => {
              onConfigChange({ ...field, block: f });
            }}
            onParentConfigChange={(parent: Field) => {
              onConfigChange(parent);
            }}
            onRemove={(index) => {
              onConfigChange({
                ...field,
                block: field.block?.filter((_, i) => i !== index),
              });
            }}
          ></FieldBlockEditable>
        </div>
      )}
      {field.type === "preset" && field.preset && (
        <div className="flex space-x-4 w-full">
          <FieldMenuEditor
            general={general}
            field={field}
            onChange={(config) => {
              onConfigChange({
                ...field,
                options: {
                  ...field.options,
                  ...config.options,
                },
                expressions: {
                  ...field.expressions,
                  ...config.expressions,
                },
              });
              setOptions({ ...options, ...config.options });
            }}
            onKeyChange={(key) => onConfigChange({ ...field, key })}
          />
          <div className="p-6 w-full border rounded space-y-4">
            <div className="flex items-center w-full">
              <Tag size={"lg"}>Preset: {field.options.title}</Tag>
              <div className="flex-grow"></div>
              <IconButton
                aria-label="Remove field"
                icon={<FaTrash />}
                onClick={() => {
                  onRemove();
                }}
              />
            </div>
            <FieldBlock
              parent={field}
              field={field.preset}
              general={general}
              layout={field.options.layout}
              value={localValue}
              valid={localValid}
              onChange={(k, v) => {
                setLocalValue((current: any) => {
                  const newLocalValue = { ...current, [k]: v };
                  onChange(newLocalValue);
                  return newLocalValue;
                });
              }}
              onValidChange={(k, v) => {
                setLocalValid((current: any) => {
                  const newLocalValid = { ...current, [k]: v };
                  onValidChange(newLocalValid);
                  return newLocalValid;
                });
              }}
            />
          </div>
        </div>
      )}
      {field.type === "array" && field.block && (
        <div className="flex space-x-4 w-full">
          <FieldMenuEditor
            field={field}
            general={general}
            onChange={(config) => {
              onConfigChange({
                ...field,
                options: {
                  ...field.options,
                  ...config.options,
                },
                expressions: {
                  ...field.expressions,
                  ...config.expressions,
                },
              });
              setOptions({ ...options, ...config.options });
            }}
            onKeyChange={(key) => onConfigChange({ ...field, key })}
          />
          <div className="flex flex-col w-full">
            <div className="flex items-start space-x-4">
              <LabelEditable
                key={field.key}
                onChange={(config) =>
                  onConfigChange({
                    ...field,
                    options: {
                      ...field.options,
                      ...config.options,
                    },
                  })
                }
                props={{ ...field } as any}
              />
              {field.options.tooltip && (
                <HelpTooltipClickable tooltip={field.options.tooltip} />
              )}
            </div>
            <div className="p-6 w-full border rounded space-y-4">
              <FormEditor
                fields={field.block as Field[]}
                general={general}
                value={value?.[0]}
                valid={valid}
                onConfigChange={(block) => {
                  onConfigChange({ ...field, block });
                }}
                onChange={(v) => {
                  onChange([v].concat((value ?? []).slice(1)));
                }}
                onValidChange={(valid) => {
                  onValidChange([valid]);
                }}
                showPresets={true}
                showPresetsBlocks={true}
              ></FormEditor>
            </div>
          </div>
          <IconButton
            aria-label="Remove field"
            icon={<FaTrash />}
            onClick={() => onRemove()}
          />
        </div>
      )}
      <RenderValidState validState={validState} />
      {!validState && <p className="text-red-600 font-bold">Campo inválido</p>}
      {!visible && <p className="text-gray-600 font-bold">Campo oculto</p>}
    </div>
  );
};
