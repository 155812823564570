import React, { useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Field } from "../form/core/Field";
import {
  parseExpressionsVariables,
  parseSubjectEnvironment,
} from "../form/core/utils/parsers";
import { loadFunctions, parseFunctions } from "../form/core/utils/parsers";

export function Protocol(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const params = new URLSearchParams(location.search);
  const [subject, setSubject] = useState<Subject | undefined>(undefined);
  const [context, setContext] = useState<any>({});
  const [valid, setValid] = useState<any>({});
  const [loading, setLoading] = useState(true);

  const fetchSubject = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_API}/subjects/${id}`,
        {
          params: {
            stage: params.get("stage"),
          },
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      setSubject(response.data);
    } catch (e) {}

    setLoading(false);
  };

  const handleProtocol = async (protocol: any) => {
    if (loading === true) {
      return;
    }

    setLoading(true);

    try {
      if (subject) {
        await axios.post(
          `${process.env.REACT_APP_BACK_END_API}/protocols`,
          {
            // TODO: Refactor para pagar os dados do subject no backend
            title: subject?.field.options.title,
            description: subject?.field.options.description,
            field: subject?.field,
            protocol,
            document: subject?.document,
            plate: subject?.plate,
            acceptance: subject?.acceptance,
            tax: subject?.tax,
            integrations: subject?.integrations,
            environment: {
              ...subject?.$environment,
              ...parseSubjectEnvironment(subject.environment ?? []),
              $sql: params.get("sql"),
            },
            function: {
              ...(subject?.$function ?? ""),
              ...(subject?.library ? { $: subject?.library } : {}),
            },
          },
          {
            headers: {
              authorization: `${localStorage.getItem("token")}`,
            },
          }
        );

        navigate("/protocols");
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const lastStepkey = subject?.field?.block?.[subject.field.block.length - 1]
      .key as string;

    if (lastStepkey && valid?.[lastStepkey]?.$complete === true) {
      handleProtocol(context);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid]);

  useEffect(() => {
    fetchSubject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const environment = subject
    ? {
        ...subject.$environment,
        ...parseSubjectEnvironment(subject.environment ?? []),
        $sql: params.get("sql"),
      }
    : {};

  const field = subject ? JSON.parse(JSON.stringify(subject.field)) : {};
  const general = subject
    ? {
        $user: subject?.$user,
        $environment: environment,
        $global: context,
        $library: {
          ...parseFunctions(subject?.$function ?? {}),
          $: loadFunctions(subject?.library ?? ""),
        },
        $tree: parseExpressionsVariables(field),
        $state: "view",
      }
    : {};

  return (
    <div className="flex flex-col space-y-6 sm:px-0 md:px-6 mt-6 mb-24">
      <div className="flex justify-center">
        <div
          className="flex flex-col justify-center mx-6 md:mx-0 justify-center space-y-4"
          style={{ width: window.innerWidth <= 500 ? "auto" : "882px" }}
        >
          <h1 className="text-2xl md:text-3xl font-black mb-6 text-center">
            {subject?.field.options.title}
          </h1>
        </div>
      </div>

      {subject?.field && (
        <div className={`flex justify-center ${loading ? "hidden" : ""}`}>
          <div
            className="flex flex-col mx-6 md:mx-0 justify-center space-y-4"
            style={{ width: window.innerWidth <= 500 ? "auto" : "882px" }}
          >
            <Field
              context={context}
              validContext={valid}
              general={general}
              field={field}
              value={context}
              valid={valid}
              onChange={(value) => {
                setContext(value);
              }}
              onValidChange={(valid: any) => {
                setValid(valid);
              }}
            ></Field>
          </div>
        </div>
      )}

      <div className="flex justify-center">
        <div
          className="flex flex-col justify-center mx-6 md:mx-0 justify-center space-y-4"
          style={{ width: window.innerWidth <= 500 ? "auto" : "882px" }}
        >
          {loading && (
            <div className="mt-10 text-center">
              <Spinner size="xl" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
