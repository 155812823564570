import { Spinner } from "@chakra-ui/react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SL } from "../../components";
import EditableHeader from "../../components/EditableHeader";
import { useSnackbar } from "../../hooks/snackbar";
import { StyleContext } from "../../reducers";
import { HotkeyContext } from "../../reducers/hotkeys.reducer";
import { parseAndExecuteTaxExpr } from "../../utils";
import { AcceptanceEditor } from "./components/AcceptanceEditor";
import { CodeEditor } from "./components/CodeEditor";
import { DocumentEditor } from "./components/DocumentEditor";
import { EnvironmentEditor } from "./components/EnvironmentEditor";
import { Integrations } from "./components/Integrations";
import { PlateEditor } from "./components/PlateEditor";
import { VersionsMenu } from "./components/VersionsMenu";
import { FieldEditable } from "./core/FieldEditable";
import { isValidJsonStr } from "./core/utils/is-valid-json-str";
import {
  loadFunctions,
  parseFunctions,
  parseSubjectEnvironment,
} from "./core/utils/parsers";
import { buildLinesTxt } from "./core/utils/validation-code";

export function SubjectEditor(): JSX.Element {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { id } = useParams();
  const [config, setConfig] = useState(JSON.stringify("{}", null, 4));
  const [subject, setSubject] = useState<Subject>();
  const [versions, setVersions] = useState<
    {
      id: string;
      version: number;
      commitMessage: string;
      timestamp: string;
      createdBy: string;
      stage?: string;
    }[]
  >([]);
  const [currentVersion, setCurrentVersion] = useState<{
    version: number;
    stage?: string;
  }>({ version: 0 });
  const [context, setContext] = useState<any>({});
  const [valid, setValid] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [taxResult, setTaxResult] = useState<any>("");
  const [validationConfig, setValidationConfig] = useState<any[]>([]);
  const styleContext = useContext(StyleContext);
  const hotkeyContext = useContext(HotkeyContext);

  const handleSetVersion = async (id: string) => {
    const versions = await axios.get(
      `${process.env.REACT_APP_BACK_END_API}/subjects/${id}/versions`,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );
    const developmentVersions = versions.data.filter(
      (data: any) => data.stage !== "approval" && data.stage !== "production"
    );

    setVersions(versions.data);
    setCurrentVersion({
      version: developmentVersions.length,
      stage:
        developmentVersions?.[developmentVersions.length - 1]?.stage ??
        "development",
    });
  };

  const fetchSubject = async () => {
    if (id !== "new" && id !== undefined) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACK_END_API}/subjects/${id}`,
        {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      setSubject(response.data);
      setConfig(JSON.stringify(response.data, null, 4));
      await handleSetVersion(id);
    } else {
      const subject: Partial<Subject> = {
        field: {
          key: "root",
          type: "block",
          options: {
            layout: "step",
            title: "Novo Assunto",
            description: "Insira a descrição",
          },
          block: [],
        },
        acceptance: {},
        environment: [],
        library: "",
        tax: defaultTax,
      };

      setSubject(subject as Subject);
      setConfig(JSON.stringify(subject, null, 4));
    }

    setLoading(false);
  };

  const fetchSubjectVersion = async (versionId: number, stage?: string) => {
    setLoading(true);

    const response = await axios.get(
      `${process.env.REACT_APP_BACK_END_API}/subjects/${id}/versions/${versionId}`,
      {
        params: {
          stage,
        },
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    delete response.data.id;

    setSubject(response.data);
    setCurrentVersion({ version: versionId, stage: stage });
    setConfig(JSON.stringify(response.data, null, 4));
    setLoading(false);
  };

  const validateConfigsAndParse = () => {
    if (!isValidJsonStr(config) || validationConfig.length) {
      snackbar.error(
        `Há um erro no JSON de configuração, por favor verifique ${buildLinesTxt(
          validationConfig
        )}`
      );

      return false;
    }

    return JSON.parse(config);
  };

  const handleSave = async () => {
    const newConfig = validateConfigsAndParse();
    if (!newConfig) return;

    try {
      const commitMessage = await prompt(
        "Insira a mensagem de alteração da versão:"
      );

      if (!commitMessage?.trim()) {
        return;
      }

      setLoading(true);

      if (id !== "new") {
        await axios.put(
          `${process.env.REACT_APP_BACK_END_API}/subjects/${id}`,
          {
            field: newConfig.field,
            acceptance: newConfig.acceptance,
            document: newConfig.document ?? {},
            plate: newConfig.plate ?? {},
            library: newConfig.library ?? {},
            environment: newConfig.environment,
            tax: newConfig.tax ?? "",
            integrations: newConfig.integrations,
            commitMessage,
          },
          {
            headers: {
              authorization: `${localStorage.getItem("token")}`,
            },
          }
        );

        await handleSetVersion(id as string);
      } else {
        setLoading(true);

        const response = await axios.post(
          `${process.env.REACT_APP_BACK_END_API}/subjects`,
          newConfig,
          {
            headers: {
              authorization: `${localStorage.getItem("token")}`,
            },
          }
        );

        navigate(`/subjects/${response.data.id}/`);

        await handleSetVersion(response.data.id);

        snackbar.success("Sucesso ao salvar o assunto")
      }
    } catch (error) {
      snackbar.unexpectedError();
      console.log(error);
    }

    setLoading(false);
  };

  const handleSetApprovalStage = async () => {
    const commitMessage = await prompt(
      "Insira a mensagem de publicação de uma nova versão para homologação:"
    );

    if (!commitMessage?.trim()) {
      return;
    }

    setLoading(true);

    try {
      await axios.put(
        `${process.env.REACT_APP_BACK_END_API}/subjects/${id}/approval`,
        {
          versionId: currentVersion.version,
          commitMessage,
        },
        {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const handleSetProductionStage = async () => {
    const commitMessage = await prompt(
      "Insira a mensagem de publicação de uma nova versão para produção:"
    );

    if (!commitMessage?.trim()) {
      return;
    }

    setLoading(true);

    try {
      await axios.put(
        `${process.env.REACT_APP_BACK_END_API}/subjects/${id}/production`,
        {
          versionId: currentVersion.version,
          commitMessage,
        },
        {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);

    const response = window.confirm(
      "Tem certeza que deseja remover este assunto?"
    );

    if (!response) {
      setLoading(false);
      return;
    }

    try {
      await axios.delete(
        `${process.env.REACT_APP_BACK_END_API}/subjects/${id}`,
        {
          headers: {
            authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
      navigate("/subjects");
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const handleSetSubjectAttr = (key: string, value: any) => {
    setSubject((prev: any) => {
      const newValue = {
        ...prev,
        [key]: value,
      };

      setConfig(JSON.stringify(newValue, null, 4));
      return newValue;
    });
  };

  const handleSetFieldConfig = (key: string, value: any) => {
    setSubject((prev: any) => {
      const newValue = {
        ...prev,
        field: {
          ...prev.field,
          options: {
            ...prev.field.options,
            [key]: value,
          },
        },
      };

      setConfig(JSON.stringify(newValue, null, 4));
      return newValue;
    });
  };

  useEffect(() => {
    fetchSubject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tax = JSON.stringify(
      parseAndExecuteTaxExpr(subject?.tax ?? defaultTax, context, general),
      null,
      4
    );

    setTaxResult(tax);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  useEffect(() => {
    hotkeyContext.dispatch({
      type: "SET_HOTKEY",
      payload: {
        S: () => !loading && handleSave(),
        1: () => !loading && setSubPage("subject"),
        2: () => !loading && setSubPage("acceptance"),
        3: () => !loading && setSubPage("document"),
        4: () => !loading && setSubPage("plate"),
        5: () => !loading && setSubPage("tax"),
        6: () => !loading && setSubPage("environment"),
        7: () => !loading && setSubPage("function"),
        8: () => !loading && setSubPage("data"),
        9: () => !loading && setSubPage("code"),
        I: () => !loading && setSubPage("integrations"),
      },
    });

    return () => {
      hotkeyContext.dispatch({
        type: "UNSET_HOTKEY",
        delete: ["S", "1", "2", "3", "4", "5", "6", "7", "8", "9", "I"],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, config, subject]);

  const [subPage, setSubPage] = useState("subject");
  const editionSubPage = [
    {
      label: "Formulário",
      shortcut: 1,
      onClick: () => {
        setSubPage("subject");
      },
    },
    {
      label: "Aceite",
      shortcut: 2,
      onClick: () => {
        setSubPage("acceptance");
      },
    },
    {
      label: "Documento",
      shortcut: 3,
      onClick: () => {
        setSubPage("document");
      },
    },
    {
      label: "Placa",
      shortcut: 4,
      onClick: () => {
        setSubPage("plate");
      },
    },
    {
      label: "Taxas",
      shortcut: 5,
      onClick: () => {
        setSubPage("tax");
      },
    },
    {
      label: "Variáveis do Assunto",
      shortcut: 6,
      onClick: () => {
        setSubPage("environment");
      },
    },
    {
      label: "Biblioteca de Funções",
      shortcut: 7,
      onClick: () => {
        setSubPage("function");
      },
    },
    {
      label: "Dados do Formulário",
      shortcut: 8,
      onClick: () => {
        setSubPage("data");
      },
    },
    {
      label: "Código do Assunto",
      shortcut: 9,
      onClick: () => {
        setSubPage("code");
      },
    },
    {
      label: "Integrações",
      shortcut: "I",
      onClick: () => {
        setSubPage("integrations");
      },
    },
  ];

  const defaultTax = `/*
Retorno esperado:
{
  principal: number; // ex: 100.50 (cem reais e cinquenta centavos)
  multa: number;
  correcao: number;
  mora: number;
  desconto: number;
  descricao: string;
  formaCalculo: string; 
  reclassificacao: number
}[]
*/  
function tax(context, general) {
  return [{
    principal: 0,
    multa: 0,
    correcao: 0,
    mora: 0,
    desconto: 0,
    descricao: "",
    formaCalculo: "",
    reclassificacao: 0
  }];
}
`;

  const environment = subject
    ? {
        ...subject.$environment,
        ...parseSubjectEnvironment(subject.environment ?? []),
      }
    : {};

  const general = {
    $user: subject?.$user,
    $environment: environment,
    $global: context,
    $library: {
      ...parseFunctions(subject?.$function ?? {}),
      $: loadFunctions(subject?.library ?? ""),
    },
    $state: "edit",
  };

  return (
    <>
      <div className="flex space-y-6 justify-center sm:px-0 md:px-6 mb-6">
        <div className="flex flex-col mx-6 md:mx-0 justify-center space-y-4 w-full">
          {loading && (
            <div className="pt-10 text-center">
              <Spinner size="xl" />
            </div>
          )}
          {!loading && (
            <div className="flex">
              <div className="flex-grow"></div>
              <div className="flex-col w-2/5">
                <EditableHeader
                  value={subject?.field.options.title}
                  onTextChange={(text) => {
                    handleSetFieldConfig("title", text);
                  }}
                  className="text-xl md:text-3xl font-black mb-2 text-center"
                />
                <EditableHeader
                  value={subject?.field.options.description}
                  onTextChange={(text) =>
                    handleSetFieldConfig("description", text)
                  }
                  className="mb-2 text-center"
                />
              </div>
              <div className="flex-grow"></div>
              <div>
                <VersionsMenu
                  versions={versions}
                  defaultVersion={currentVersion}
                  callback={fetchSubjectVersion}
                ></VersionsMenu>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex w-full mb-40">
        <div
          className="flex flex-col space-y-6 border-2 "
          style={{ minWidth: "250px", width: "250px" }}
        >
          <div className="mx-0 px-0 mt-4">
            {editionSubPage.map((item) => (
              <div
                className={`px-6 py-2.5 ${
                  styleContext.state.backgroundColor === "#000000"
                    ? "hover:bg-gray-600"
                    : "hover:bg-gray-200"
                }`}
              >
                <button
                  onClick={item.onClick}
                  className="flex justify-between w-full items-center"
                >
                  {item.label} <SL>{item.shortcut}</SL>
                </button>
              </div>
            ))}
            <div className="flex-grow border-b-2 border-t-2 py-4"></div>
            <div
              className={`px-6 py-2.5 ${
                styleContext.state.backgroundColor === "#000000"
                  ? "hover:bg-gray-600"
                  : "hover:bg-gray-200"
              }`}
            >
              <button
                className="flex justify-between w-full items-center"
                disabled={loading}
                onClick={handleSave}
              >
                Salvar <SL>S</SL>
              </button>
            </div>
            <div
              className={`px-6 py-2.5 ${
                styleContext.state.backgroundColor === "#000000"
                  ? "hover:bg-gray-600"
                  : "hover:bg-gray-200"
              }`}
            >
              <button
                className="flex justify-between w-full items-center"
                disabled={loading}
                onClick={handleSetApprovalStage}
              >
                Homologar
              </button>
            </div>
            <div
              className={`px-6 py-2.5 ${
                styleContext.state.backgroundColor === "#000000"
                  ? "hover:bg-gray-600"
                  : "hover:bg-gray-200"
              }`}
            >
              <button
                className="flex justify-between w-full items-center"
                disabled={loading}
                onClick={handleSetProductionStage}
              >
                Publicar
              </button>
            </div>
            <div className="flex-grow border-b-2 border-t-2 pt-10"></div>
            {!loading && id !== "new" && (
              <div
                className={`px-6 py-2.5 ${
                  styleContext.state.backgroundColor === "#000000"
                    ? "hover:bg-gray-600"
                    : "hover:bg-gray-200"
                }`}
              >
                <button
                  className="flex justify-between text-red-500 items-center"
                  disabled={loading}
                  onClick={handleDelete}
                >
                  Apagar Assunto
                </button>
              </div>
            )}
          </div>
        </div>
        {!loading && subject && (
          <div className="flex w-full justify-center mb-48">
            <div
              className=""
              style={{
                width: "1100px",
                display: subPage !== "subject" ? "none" : "",
              }}
            >
              <FieldEditable
                context={context}
                validContext={valid}
                general={general}
                field={subject.field}
                value={context}
                valid={valid}
                onChange={(value) => setContext(value)}
                onValidChange={(valid) => setValid(valid)}
                onConfigChange={(field: Field) => {
                  handleSetSubjectAttr("field", field);
                }}
                onRemove={() => {}}
              />
            </div>
            {subPage === "acceptance" && (
              <AcceptanceEditor
                acceptance={subject.acceptance ?? {}}
                config={{
                  environment: general.$environment,
                  function: general.$library,
                  $user: general.$user as UserProps,
                }}
                onChange={(config) => {
                  handleSetSubjectAttr("acceptance", config);
                }}
              />
            )}
            {subPage === "document" && (
              <DocumentEditor
                value={subject.document}
                field={subject.field}
                context={context}
                onChange={(value) => {
                  const newSubject = {
                    ...subject,
                    document: value,
                  };
                  setSubject(newSubject);
                  setConfig(JSON.stringify(newSubject, null, 4));
                }}
                general={general}
              />
            )}
            {subPage === "plate" && (
              <PlateEditor
                value={subject.plate}
                field={subject.field}
                context={context}
                onChange={(value) => {
                  const newSubject = {
                    ...subject,
                    plate: value,
                  };
                  setSubject(newSubject);
                  setConfig(JSON.stringify(newSubject, null, 4));
                }}
                general={general}
              />
            )}
            {subPage === "tax" && (
              <div className="flex-col space-y-6 w-full">
                <CodeEditor
                  config={
                    subject.tax === null ||
                    subject.tax === undefined ||
                    subject.tax?.trim() === ""
                      ? defaultTax
                      : subject.tax
                  }
                  onChange={(value) => handleSetSubjectAttr("tax", value)}
                  language="javascript"
                  height="50vh"
                />
                <div>
                  <div className="px-6 pb-6 text-xl font-bold">Resultado:</div>
                  <div>
                    <CodeEditor
                      config={taxResult}
                      onChange={() => {}}
                      language="json"
                      height="50vh"
                    />
                  </div>
                </div>
              </div>
            )}
            {subPage === "environment" && (
              <div style={{ width: "100%" }}>
                <EnvironmentEditor
                  environments={subject.environment ?? []}
                  onChange={(environments) => {
                    handleSetSubjectAttr("environment", environments);
                  }}
                />
              </div>
            )}
            {subPage === "function" && (
              <CodeEditor
                config={subject.library}
                onChange={(value) => handleSetSubjectAttr("library", value)}
                language="javascript"
              />
            )}
            {subPage === "data" && (
              <CodeEditor
                config={JSON.stringify(
                  {
                    context,
                    general,
                  },
                  null,
                  2
                )}
                onChange={() => {}}
              />
            )}{" "}
            {subPage === "code" && (
              <CodeEditor
                config={config}
                onChange={setConfig}
                onValidate={setValidationConfig}
              />
            )}
            {subPage === "integrations" && (
              <Integrations
                data={subject.integrations?.sei}
                onChange={(sei) => {
                  handleSetSubjectAttr("integrations", {
                    ...(subject.integrations ?? {}),
                    sei,
                  });
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}
