import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { Editor, Monaco } from "@monaco-editor/react";
import React, { FC, useContext, useEffect, useState } from "react";
import { Field } from "../modules/form/core/Field";
import { isValidJsonStr } from "../modules/form/core/utils/is-valid-json-str";
import { generateSuggestionsFromObject } from "../modules/form/core/utils/suggestions";
import {
  buildLinesTxt,
  isValidExpression,
} from "../modules/form/core/utils/validation-code";
import { StyleContext } from "../reducers";
import HelpTooltipClickable from "./HelpTooltipCliclable";
import SL from "./ShortcutLabel";

type PromptResolve = (value: string | null) => void;

let resolvePrompt: PromptResolve | null = null;
let setPromptParams: React.Dispatch<React.SetStateAction<PromptProps>>;

interface IPromptPropsOptions {
  code?: boolean;
  tooltip?: string;
  validator?: (value: any) => boolean;
  errorMessage?: string;
  intellisenseObj?: {
    obj: any;
    docs?: any;
  };
}

interface PromptProps {
  title?: string;
  defaultValue?: string;
  fields?: Field[];
  options?: IPromptPropsOptions;
}

const PromptModal: FC<PromptProps> = ({
  title = "",
  defaultValue = "",
  fields,
  options,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [input, setInput] = useState<string>(defaultValue);
  const [modalTitle, setModalTitle] = useState<string>(title);
  const [formFields, setFields] = useState<any[]>(fields || []);
  const [modalOptions, setModalOptions] = useState<IPromptPropsOptions>(
    options || {}
  );
  const [promptParams, setPromptParamsState] = useState<PromptProps>({});
  const [form, setForm] = useState<any>(input || {});
  const [valid, setValid] = useState<any>({});
  const [hasError, setHasError] = useState<boolean>(false);
  const [editorError, setEditorError] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const styleContext = useContext(StyleContext);

  setPromptParams = setPromptParamsState;

  useEffect(() => {
    setInput(promptParams?.defaultValue || "");
    setModalTitle(promptParams?.title || "");
    setFields(promptParams?.fields || []);
    setForm(promptParams?.defaultValue || {});
    setModalOptions(promptParams?.options || {});
    if (resolvePrompt) {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolvePrompt, promptParams]);

  useEffect(() => {
    const { validator } = promptParams?.options ?? {};
    let resultValidate = false;
    if (validator) resultValidate = !validator(input);

    if (hasError !== resultValidate) setHasError(resultValidate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const handleConfirm = () => {
    if (resolvePrompt) {
      if (formFields?.length) {
        resolvePrompt(form);
      } else {
        if (modalOptions.code) {
          if (!validateEditor(editorError)) return;
        }
        resolvePrompt(input);
      }
    }
    onClose();
  };

  const handleCancel = () => {
    if (resolvePrompt) {
      resolvePrompt(null);
    }
    onClose();
  };

  (window as any).prompt = (
    title: string,
    defaultValue?: string,
    fields?: any[],
    options?: any
  ) => {
    return new Promise<string | null>((resolve) => {
      if (setPromptParams) {
        setPromptParams({
          title,
          defaultValue,
          fields,
          options,
        });
      }
      resolvePrompt = resolve;
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && e.shiftKey) {
      handleConfirm();
    }
  };

  const validateEditor = (error: any) => {
    setEditorError(error);

    if (isValidJsonStr(input)) {
      setHasError(false);
      setErrorMessage(undefined);
      return true;
    }

    const result = isValidExpression(input);
    setHasError(error.length > 0 || !result.valid);

    if (error.length) {
      setErrorMessage(
        `Há um erro no código, por favor verifique ${buildLinesTxt(error)}`
      );
      return false;
    }

    if (!result.valid) {
      setErrorMessage(result.message);
      return false;
    } else {
      setErrorMessage(undefined);
      return true;
    }
  };

  const renderError = () => {
    const { errorMessage: message } = promptParams?.options ?? {};
    if (!hasError || (!message && !errorMessage)) return;

    return (
      <>
        <div role="alert" className="w-full mt-3">
          <div className="border border-red-400 rounded bg-red-100 px-4 py-3 text-red-700">
            <p>{message ?? errorMessage}</p>
          </div>
        </div>
      </>
    );
  };

  const configureEditor = (monaco: Monaco) => {
    if (monaco && modalOptions && modalOptions?.intellisenseObj?.obj)
      monaco.languages.registerCompletionItemProvider("javascript", {
        provideCompletionItems: () => {
          const suggestions: any[] = [];

          if (modalOptions?.intellisenseObj?.obj)
            Object.keys(modalOptions?.intellisenseObj?.obj).forEach((key) => {
              suggestions.push(
                ...generateSuggestionsFromObject({
                  monaco,
                  obj: modalOptions?.intellisenseObj?.obj?.[key],
                  objectName: key,
                  docs: modalOptions?.intellisenseObj?.docs?.[key],
                })
              );
            });

          return {
            suggestions,
          };
        },
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCancel}>
      <ModalOverlay />
      <ModalContent
        className="px-4 py-6"
        style={{ minWidth: "600px" }}
        bg={styleContext.state.backgroundColor}
      >
        <ModalHeader>
          <div className="flex items-center space-x-4">
            <span>{modalTitle}</span>
            {modalOptions?.tooltip && (
              <div className="font-normal text-sm">
                <HelpTooltipClickable tooltip={modalOptions.tooltip} />
              </div>
            )}
          </div>
        </ModalHeader>
        <ModalBody>
          {!formFields.length &&
            (modalOptions.code ? (
              <Editor
                height="30vh"
                defaultLanguage="javascript"
                defaultValue={input}
                theme="vs-dark"
                onChange={(value) => setInput(value ?? "")}
                onValidate={(error) => validateEditor(error)}
                beforeMount={configureEditor}
              />
            ) : (
              <Textarea
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                isInvalid={hasError}
                size="lg"
                height={200}
              />
            ))}
          {formFields &&
            formFields.map((field: any) => (
              <Field
                parent={field}
                key={field.key}
                general={{}}
                field={field}
                value={form[field.key]}
                valid={valid[field.key]}
                onChange={(value) => {
                  setForm({ ...form, [field.key]: value });
                }}
                onValidChange={(v: boolean) => {
                  setValid({ ...valid, [field.key]: v });
                }}
                context={form[field.key]}
                validContext={valid[field.key]}
              />
            ))}
          {renderError()}
        </ModalBody>
        <ModalFooter>
          <button
            className="bg-yellow-600 hover:bg-yellow-700 text-white p-2.5 px-4 rounded-md  mr-4"
            disabled={hasError}
            onClick={handleConfirm}
          >
            <span className="mr-2">Confirmar</span>
            <SL bg="yellow.500">Shift+Enter</SL>
          </button>
          <button
            className="bg-transparent p-2.5 rounded-md"
            onClick={handleCancel}
          >
            <span className="mr-2">Cancel</span> <SL>esc</SL>
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PromptModal;
