import { Monaco } from "@monaco-editor/react";

export interface IGenerateSuggestionsFromObjectProps {
  monaco: Monaco;
  objectName: string;
  obj: Record<string, any>;
  parentKey?: string;
  docs?: Record<string, any>;
}

const validAndTransformKey = (
  key: string | undefined,
  useDotInStart = false
) => {
  if (!key) return key;
  const isValid = /^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(key);

  return isValid ? `${useDotInStart ? "." : ""}${key}` : `["${key}"]`;
};

const getKeyAccess = (
  objectName: string,
  parentKey: string | undefined,
  key: string
) => {
  return `${objectName}.${
    parentKey
      ? `${parentKey}${validAndTransformKey(key, true)}`
      : validAndTransformKey(key)
  }`;
};

// Função genérica para gerar sugestões com base em um objeto
export const generateSuggestionsFromObject = (
  props: IGenerateSuggestionsFromObjectProps
): Array<any> => {
  const { monaco, objectName, obj, parentKey, docs } = props;
  return Object.keys(obj).flatMap((key) => {
    const currentKey = getKeyAccess(objectName, parentKey, key); // Chave atual completa
    const value = obj[key];

    // Verifica se o valor é um objeto (aninhado)
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      // Se for um objeto, chama a função recursivamente para gerar sugestões para o nível aninhado
      return generateSuggestionsFromObject({
        ...props,
        obj: value,
        parentKey: parentKey
          ? `${parentKey}${validAndTransformKey(key, true)}`
          : validAndTransformKey(key),
        docs: docs?.[key],
      });
    }

    // Define o tipo detalhado para documentação
    const typeDetail = Array.isArray(value) ? "Array" : typeof value;

    return {
      label: currentKey,
      kind: monaco.languages.CompletionItemKind.Property,
      insertText: currentKey,
      documentation: docs?.[key]
        ? docs?.[key]
        : `
      **${key}** - ${typeDetail}

      ### Exemplo

      \`\`\`javascript
      const value = ${objectName}.${currentKey};
      \`\`\`
    `,
      detail: typeDetail,
      sortText: "0",
    };
  });
};

// Exemplo de uso com o objeto de usuário
const userObject = {
  email: "douglasdm97@gmail.com",
  id: "ac02af23-596c-431c-8591-acb1c81e7205",
  document: "082.917.639-09",
  name: "Douglas Gabriel Cardoso",
  custom: {
    address: "Rua presidente jk 1603",
    cep: "85807-440",
    phone: "(45) 99101-9920",
  },
  timestamp: "2024-04-15T19:10:03.140Z",
  updatedAt: "2024-09-23T13:02:44.786Z",
};

// Chamando a função para gerar sugestões com base no objeto userObject
export const UserSuggestions = (monaco: Monaco) =>
  generateSuggestionsFromObject({
    monaco,
    objectName: "user",
    obj: userObject,
    docs: {
      email: "EMAIL DO USUÁRIO",
    },
  });
