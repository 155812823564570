import React, { useState } from "react";
import { AddFieldMenu } from "../components/AddFieldMenu";
import { FieldMenuEditor } from "../components/FieldMenuEditor";
import { FieldEditable } from "./FieldEditable";
import { StepEditable } from "./fields";
import { FieldBlockEditableProps } from "./utils/types";

export const FieldBlockEditable: React.FC<FieldBlockEditableProps> = ({
  parent,
  field,
  layout = "block",
  general,
  value,
  valid,
  onChange,
  onValidChange,
  onConfigChange,
  onParentConfigChange,
  onRemove,
}): JSX.Element => {
  const [startDraggingIndex, setStartDraggingIndex] = useState<number | null>(
    null
  );
  const [currentDraggingIndex, setCurrentDraggingIndex] = useState<
    number | null
  >(null);

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    setStartDraggingIndex(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.preventDefault();
    if (startDraggingIndex === null) return;

    let newIndex = startDraggingIndex < index ? index + 1 : index;

    newIndex = newIndex > field.length ? field.length : newIndex;

    if (startDraggingIndex !== newIndex) {
      setCurrentDraggingIndex(newIndex);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    setStartDraggingIndex(null);

    if (startDraggingIndex !== null && currentDraggingIndex !== null) {
      const itemToMove = field.splice(startDraggingIndex, 1)[0];

      field.splice(
        startDraggingIndex < currentDraggingIndex
          ? currentDraggingIndex - 1
          : currentDraggingIndex,
        0,
        itemToMove
      );

      onConfigChange(field ?? []);
      setStartDraggingIndex(null);
      setCurrentDraggingIndex(null);
    }
  };

  return (
    <>
      {parent.options.hideEditMenu !== true && (
        <div>
          <FieldMenuEditor
            general={general}
            field={parent}
            onChange={(config) => {
              parent = {
                ...parent,
                options: {
                  ...parent.options,
                  ...config.options,
                },
                expressions: {
                  ...parent.expressions,
                  ...config.expressions,
                },
              };
              onParentConfigChange(parent);
            }}
            onKeyChange={(key) => {
              parent.key = key;
              onParentConfigChange(parent);
            }}
          />
          <label className="font-black ml-2">{parent.key}</label>
        </div>
      )}

      <div className="mt-10">
        {layout === "step" && (
          <StepEditable
            field={field}
            general={general}
            value={value}
            valid={valid}
            onChange={onChange}
            onValidChange={onValidChange}
            onConfigChange={onConfigChange}
          />
        )}
        {layout === "block" && (
          <div
            className={`${
              parent.options.card && parent.options.open === false
                ? "hidden"
                : ""
            }`}
          >
            {field.map((f, index) => (
              <div
                key={`parent-${parent.key}-field-${index}`}
                className="mb-4"
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => handleDragOver(e, index)}
                onDrop={handleDrop}
              >
                <FieldEditable
                  parent={parent}
                  context={value}
                  validContext={valid}
                  general={general}
                  field={f}
                  value={value?.[f.options.key ?? f.key]}
                  valid={valid?.[f.options.key ?? f.key]}
                  onChange={(v) => {
                    onChange(f.options.key ?? f.key, v);
                  }}
                  onValidChange={(valid) => {
                    onValidChange(f.options.key ?? f.key, valid);
                  }}
                  onConfigChange={(config: Field) => {
                    field[index] = config;
                    onConfigChange(field);
                  }}
                  onRemove={() => onRemove(index)}
                ></FieldEditable>
              </div>
            ))}
            <div className="flex justify-center mt-10">
              <AddFieldMenu
                addFieldCallback={(config: Field | null) => {
                  if (config) {
                    field?.push(config);
                    onConfigChange(field);
                  }
                }}
                showPresets={true}
                showPresetsBlocks={true}
              ></AddFieldMenu>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
