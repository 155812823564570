import { IconButton, Tag } from "@chakra-ui/react";
import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Field } from "../core/Field";
import { FieldEditable } from "../core/FieldEditable";
import { AddFieldMenu } from "./AddFieldMenu";
import { FieldMenuEditor } from "./FieldMenuEditor";

export interface FormEditorProps {
  fields: Field[];
  general: any;
  value?: any;
  valid?: { [key: string]: boolean };
  onConfigChange: (field: Field[]) => void;
  onChange?: (value: any) => void;
  onValidChange?: (valid: { [key: string]: boolean }) => void;
  fieldLimitNumber?: number;
  showPresets?: boolean;
  showPresetsBlocks?: boolean;
}

export const FormEditor: React.FC<FormEditorProps> = ({
  fields,
  general,
  value,
  valid: validInput,
  onConfigChange,
  onChange,
  onValidChange,
  fieldLimitNumber = 1000,
  showPresets = true,
  showPresetsBlocks = false,
}): JSX.Element => {
  const [form, setForm] = useState<any>(value ?? {});
  const [valid, setValid] = useState<any>(validInput ?? {});
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

  const handleRemoveField = (layerIndex: number) => {
    const form = [...fields];
    form.splice(layerIndex, 1);
    onConfigChange(form);
  };

  const handleAddField = (field: Field | null) => {
    if (field !== null) {
      const newFields = [...fields];
      newFields.push(field);
      onConfigChange(newFields);
    }
  };

  const handleSetField = (index: number, field: any) => {
    const form = [...fields];
    form[index] = field;
    onConfigChange(form);
  };

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    setDraggingIndex(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    e.preventDefault();
    if (draggingIndex === null) return;

    let newIndex = draggingIndex < index ? index + 1 : index;

    newIndex = newIndex > fields.length ? fields.length : newIndex;

    if (draggingIndex !== newIndex) {
      const itemToMove = fields.splice(draggingIndex, 1)[0];

      if (draggingIndex < newIndex) {
        newIndex--;
      }

      fields.splice(newIndex, 0, itemToMove);

      onConfigChange(fields ?? []);
      setDraggingIndex(newIndex);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="flex flex-col space-y-4 mt-4">
        {fields.map((field, index) => {
          return (
            <div
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDrop={(e) => handleDrop(e, index)}
              onDragOver={(e) => handleDragOver(e)}
            >
              {field.type !== "preset" && (
                <FieldEditable
                  parent={field}
                  key={field.key}
                  field={field}
                  context={form}
                  validContext={valid}
                  general={general}
                  value={form[field.key]}
                  valid={valid[field.key]}
                  onChange={(value: any) => {
                    setForm((form: any) => {
                      const newValue = { ...form, [field.key]: value };
                      onChange?.(newValue);
                      return newValue;
                    });
                  }}
                  onValidChange={(v: boolean) => {
                    setValid((valid: any) => {
                      const newValid = { ...valid, [field.key]: v };
                      onValidChange?.(newValid);
                      return newValid;
                    });
                  }}
                  onConfigChange={(config) => handleSetField(index, config)}
                  onRemove={() => {
                    handleRemoveField(index);
                  }}
                />
              )}
              {field.type === "preset" && (
                <div className="flex space-x-4 items-center p-6 border rounded space-y-4">
                  <div>
                    <FieldMenuEditor
                      general={general}
                      field={field}
                      onChange={(config) =>
                        handleSetField(index, {
                          ...field,
                          options: {
                            ...field.options,
                            ...config.options,
                          },
                          expressions: {
                            ...field.expressions,
                            ...config.expressions,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="w-full">
                    <div className="flex items-center w-full">
                      <Tag className="mb-4" size={"lg"}>
                        Preset: {field.options.title}
                      </Tag>
                      <div className="flex-grow"></div>
                      <IconButton
                        aria-label="Remove field"
                        icon={<FaTrash />}
                        onClick={() => {
                          handleRemoveField(index);
                        }}
                      />
                    </div>
                    {field.preset?.map((preset) => (
                      <Field
                        parent={field}
                        key={field.options.key ?? field.key}
                        field={preset}
                        context={form?.[field.options.key ?? field.key]}
                        validContext={valid[field.options.key ?? field.key]}
                        general={general}
                        value={
                          form[field.options.key ?? field.key]?.[preset.key]
                        }
                        valid={
                          valid[field.options.key ?? field.key]?.[preset.key]
                        }
                        onChange={(value: any) => {
                          setForm((form: any) => {
                            const newValue = {
                              ...form,
                              [field.options.key ?? field.key]: {
                                ...form[field.options.key ?? field.key],
                                [preset.key]: value,
                              },
                            };
                            onChange?.(newValue);
                            return newValue;
                          });
                        }}
                        onValidChange={(v: boolean) => {
                          setValid((valid: any) => {
                            const newValid = {
                              ...valid,
                              [field.options.key ?? field.key]: {
                                ...valid[field.options.key ?? field.key],
                                [preset.key]: v,
                              },
                            };
                            onValidChange?.(newValid);
                            return newValid;
                          });
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {fields.length < fieldLimitNumber && (
        <div className="flex justify-center mt-10">
          <AddFieldMenu
            addFieldCallback={handleAddField}
            showPresets={showPresets}
            showPresetsBlocks={showPresetsBlocks}
          ></AddFieldMenu>
        </div>
      )}
    </>
  );
};
